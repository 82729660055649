import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  signOut,
  signInWithRedirect,
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  AuthUser,
  AuthSession,
} from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import { GlobalStateService } from './global-state.service';
import { filter, firstValueFrom, Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly isAuthenticatingCompleted$: Observable<boolean>;
  constructor(private globalStateService: GlobalStateService) {
    this.isAuthenticatingCompleted$ = toObservable(this.globalStateService.isAuthenticating).pipe(
      filter((bool) => !bool),
    );
    this.initAmplify();
    this.setInitialAuthGlobalState();
  }

  private initAmplify() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.userPoolId,
          userPoolClientId: environment.userPoolClientId,
          loginWith: {
            oauth: {
              domain: environment.cognitoDomain,
              scopes: environment.scopes,
              redirectSignIn: environment.redirectSignIn,
              redirectSignOut: environment.redirectSignOut,
              responseType: environment.responseType as 'token' | 'code',
            },
          },
        },
      },
    });
  }

  private async setInitialAuthGlobalState() {
    try {
      const loggedInUser = await this.fetchAuthSession();
      if (loggedInUser.tokens) {
        this.globalStateService.loggedInUser.set(loggedInUser);
      }
    } catch {
      /* empty */
    } finally {
      this.globalStateService.isAuthenticating.set(false);
    }
  }

  // App redirects out of Angular App
  singInToGoogle(): Promise<void> {
    return signInWithRedirect({ provider: 'Google' });
  }

  // App redirects out of Angular App and back to Angular App
  signOut(): Promise<void> {
    return signOut();
  }

  getCurrentUser(): Promise<AuthUser> {
    return getCurrentUser();
  }

  fetchUserAttributes(): Promise<FetchUserAttributesOutput> {
    return fetchUserAttributes();
  }

  fetchAuthSession(): Promise<AuthSession> {
    return fetchAuthSession();
  }

  async guardAuthenticated(): Promise<boolean> {
    if (this.globalStateService.isAuthenticating()) {
      await firstValueFrom(this.isAuthenticatingCompleted$);
      return this.globalStateService.isAuthenticated();
    } else {
      return this.globalStateService.isAuthenticated();
    }
  }
}
