import { computed, Injectable, Signal, signal } from '@angular/core';
import { IHeaderDetails, IBreadCrumb, ILoggedInUser } from '../shared/types/app.types';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  readonly headerDetails = signal<IHeaderDetails>({});

  readonly isAuthenticating = signal<boolean>(true);

  readonly loggedInUser = signal<ILoggedInUser>(undefined);

  readonly isAuthenticated = computed(() => !!this.loggedInUser());

  readonly loggedInUserName = computed(() => {
    const session = this.loggedInUser();
    const payload = session?.tokens?.idToken?.payload;
    if (payload) {
      return payload['name']?.toString();
    } else {
      return undefined;
    }
  });

  readonly loggedInUserRoles: Signal<string[]> = computed(() => {
    const session = this.loggedInUser();
    const payload = session?.tokens?.idToken?.payload;
    if (payload) {
      const roles = payload['cognito:groups']
        ?.toString()
        .split(',')
        .filter((role: string) => !role.includes('ap-'));
      return roles || [];
    } else {
      return [];
    }
  });

  readonly breadCrumbs = signal<IBreadCrumb[]>([]);
}
